@import "__config";

@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.scroll {
  display: flex;
  overflow: hidden;
  padding: 0 0 _calc(80);

  @media #{$small_over} {
    padding: 0 0 _calc(120);
  }

  ul {
    display: flex;
    animation: infinity-scroll-left 80s infinite linear 0.5s both;

    li {
      height: _calc(200);
      padding: 0 _calc(15) 0 0;

      @media #{$small_over} {
        height: _calc(280);
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}