@import "__config";
@import "_parts_scroll";

.mission {
  padding: _calc(40) _calc(30) _calc(100);

  @media #{$small_over} {
    padding: _calc(200) 0 _calc(150) _calc(160);
    display: flex;
    justify-content: space-between;
  }

  .head {
    @media #{$small_over} {
      flex: 1;
      padding: 0 _calc(80) 0 0;
    }
  }

  .block {
    @media #{$small_over} {
      width: _calc(730);
      padding: 0 _calc(100) 0 0;
    }

    h3 {
      font-weight: 600;
      font-size: _calc(26);
      line-height: 1.5;
      padding: _calc(50) 0 _calc(20);

      @media #{$small_over} {
        font-size: _calc(38);
        padding: 0 0 _calc(30);
      }
    }

    .text {
      font-size: .9rem;
      line-height: 2;
      padding: 0 0 _calc(20);

      @media #{$small_over} {
        font-size: 16px;
        line-height: 2.2;
      }
    }
  }
}

.outline {
  padding: _calc(40) _calc(30) _calc(80);

  @media #{$small_over} {
    padding: _calc(50) 0 _calc(150) _calc(160);
  }

  .block {
    padding: _calc(50) 0 0;

    dl {
      padding: 0 0 _calc(20);

      @media #{$small_over} {
        display: flex;
      }

      dt,
      dd {
        font-size: .9rem;

        @media #{$small_over} {
          font-size: 16px;
        }
      }

      dt {
        font-weight: 500;
        padding: _calc(15) 0 0;

        @media #{$small_over} {
          width: 200px;
        }
      }

      dd {
        padding: _calc(15) 0 0;

        @media #{$small_over} {
          width: auto;
          flex: 1;
        }

        span {
          display: block;
          font-weight: 600;
        }

        em {
          font-style: normal;
          font-weight: 500;
          display: block;
          padding: _calc(15) 0 0;

          @media #{$small_over} {
            display: inline-block;
            padding: 0 _calc(15) 0 0;
          }
        }
      }
    }
  }
}